import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/forgot.css";
import { Link } from 'react-router-dom';

function ForgotPasswordPage() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const apiPort = process.env.REACT_APP_API_PORT;
  
  const url = `${apiUrl}:${apiPort}`;
  
  const [isSuccess, setIsSuccess] = useState(false);
  
  const handleSubmit = (e) => {
    e.preventDefault(); 
    setIsSuccess(true);
  }

  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleForgotPassword = async (event) => {
    event.preventDefault();
    try {
      console.log("API URL:", url);

      const response = await fetch(
        `${url}/forgot-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );
      console.log("Received email:", email);

      console.log("Response status:", response.status);
      console.log("Response body:", await response.text());

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Response JSON:", data);

      if (data.success) {
        alert("Password reset instructions have been sent to your email.");
        navigate("/login");
      } else {
        alert(data.message || "Error processing request");
      }
    } catch (error) {
      console.error("Error during password reset:", error);
      alert("An error occurred while resetting the password.");
    }
  };

  return (
    <div className="custom-container">
      <div className="custom-card-container">
        <div className="bg4 d-none d-md-block"></div> 
        
        <div className="custom-card-body">
          <h1 className="header mb-4">Forgot Password</h1>
          <form onSubmit={handleSubmit}>
            <div className="custom-form-group mb-3">
              <input
                type="email"
                id="email"
                placeholder=""
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label htmlFor="email">Email</label>
            </div>
            <button onClick={handleForgotPassword} className="btn custom-button btn-block mb-3">
              Reset Password
            </button>
  
            {isSuccess && (
              <div className="alert alert-success" role="alert">
                Success! Password reset link has been sent to your email.
              </div>
            )}
  
            <p className="text-center">
              <Link className="btn-link" to="/login">Back To Login</Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
  
  

  
  
}

export default ForgotPasswordPage;
