import React, { useState } from 'react';

const apiUrl = process.env.REACT_APP_API_URL;
const apiPort = process.env.REACT_APP_API_PORT;

const url = `${apiUrl}:${apiPort}`;

console.log('API URL:', url);

const Register = () => {
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        try {
            
            

            const response = await fetch(`${url}/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    fname,
                    lname,
                    email,
                    password,
                }), 
            });
            
            //const result = await response.json();
            const text = await response.text();
            console.log('Response Text:', text);

            const result = JSON.parse(text);
            console.log('Parsed Result:', result);  

            console.log('Response Status:', response.status);
            console.log('Result:', result); 
            if (response.ok) {
                setMessage('Registration successful!');
            } else {
                setMessage(result.message || 'Registration failed.');
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage('An error occurred.');
        }   
        
    };

    return (
        <div className="register-container">
            <h1>Register</h1>
            <form onSubmit={handleSubmit}>
                <label>
                    First Name:
                    <input
                        type="text"
                        value={fname}
                        onChange={(e) => setFname(e.target.value)}
                        required
                    />
                </label>
                <br />
                <label>
                    Last Name:
                    <input
                        type="text"
                        value={lname}
                        onChange={(e) => setLname(e.target.value)}
                        required
                    />
                </label>
                <br />
                <label>
                    Email:
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </label>
                <br />
                <label>
                    Password:
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </label>
                <br />
                <button onClick={handleSubmit} type="submit">Register</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};


export default Register;
