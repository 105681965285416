import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/mainpage.css';

function MainPage() {
    const navigate = useNavigate();

    const handleLoginRedirect = () => {
        navigate('/login');
    };

    return (
        <div className="img">
            <nav className="nav.navbar navbar-light logo-custom"></nav>
            <div className="container d-flex flex-column justify-content-center align-items-center min-vh-100 text-center">
                <div className="row">
                    <div className="all-text col-12">
                        <p className="sarapao">BOOK NOW AND MAKE EVERY MEETING COUNT</p>
                        <h1 className="head">GET READY TO MEET!</h1>
                        <p className="sarapao">START BOOKING NOW</p>
                    </div>
                </div>
                <div className="center-button-container">
                    <button className="center-button" onClick={handleLoginRedirect}>Login</button>
                </div>
            </div>
        </div>
    );
    
    
}

export default MainPage;
