import React from 'react';

function HistoryPage() {
    return (
        <div>
            <h1>Booking History</h1>
            {/* Display booking history here */}
            <p>No bookings yet.</p>
        </div>
    );
}

export default HistoryPage;
