import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/logincustom.css"; 
import Swal from 'sweetalert2';


function AuthPage() {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullname, setFullName] = useState("");
  // const [lname, setLastName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  
  const apiUrl = process.env.REACT_APP_API_URL;
  const apiPort = process.env.REACT_APP_API_PORT;
  
  const url = `${apiUrl}:${apiPort}`;

  console.log('apiUrl : ',apiUrl)
  console.log('apiPort : ',apiPort)
  console.log('url : ',url)

  const handleToggle = () => {
    setIsLogin(!isLogin);
    setEmail("");
    setPassword("");
    setFullName("");
    setConfirmPassword("");
  };


  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${url}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });


      
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("API Response:", data);

      if (data.success) {
        navigate("/booking");
      } else {
        setLoginAttempts((prev) => {
          const attempts = prev + 1;
          if (attempts >= 4) {
            setShowForgotPassword(true);
          }
          console.log("Login Attempts:", attempts);
          return attempts;
        });
        alert(data.message || "Invalid credentials");
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }
    try {
      const response = await fetch(
        `${url}/register`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ fullname, email, password }),
        }
      );
      
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("API Response:", data);

      if (data.success) {
        alert("Registration successful! You can now log in.");
        handleToggle(); 
      } else {
        alert(data.message || "Registration failed");
      }
    } catch (error) {
      console.error("Error during registration:", error);
    }
  };

  return ( 
    <div className="custom-container">
        <div className="col-md-6 custom-card-container">
          <div className="card-body custom-card-body">
            <h3 className="card-title text-center">
              {isLogin ? "Login" : "Register"}
            </h3>
            <form onSubmit={isLogin ? handleLogin : handleRegister}>
              {!isLogin && (
                <div>
                  <div className="form-group custom-form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="fullname"
                      placeholder=" "
                      value={fullname}
                      onChange={(e) => setFullName(e.target.value)}
                      required
                    />
                    <label htmlFor="full-name">Full name</label>
                  </div>
                </div>
              )}

              
              <div className="form-group custom-form-group">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder=" "
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <label htmlFor="email">Email</label>
              </div>
              <div className="form-group custom-form-group">
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder=" "
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <label htmlFor="password">Password</label>
              </div>
              {!isLogin && (
                <div className="form-group custom-form-group">
                  <input
                    type="password"
                    className="form-control"
                    id="confirm-password"
                    placeholder=" "
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                  <label htmlFor="confirm-password">Confirm password</label>
                </div>
              )}
              <div className="text-center mt-3">
                {isLogin && (
                  <a href="/forgot-password" className="custom-link">
                    Forgot Password?
                  </a>
                )}
                <p className="mt-2">
                  {isLogin
                    ? "Don't have an account?"
                    : "Already have an account?"}{" "}
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={handleToggle}
                  >
                    {isLogin ? "Register" : "Login"}
                  </button>
                </p>
                <button type="submit" className="btn no-hover btn-block">
                  {isLogin ? "Login" : "Register"}
                </button>
              </div>
            </form>
          </div>
        </div>
      
    </div>
  );
}

export default AuthPage;
