import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import '../styles/reset.css';
function ResetPasswordPage() {
    const { token } = useParams(); // รับ token จาก URL
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;
    const apiPort = process.env.REACT_APP_API_PORT;

    const url = `${apiUrl}:${apiPort}`;

    const handleResetPassword = async (event) => {
        event.preventDefault();
        
        if (newPassword !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        alert("Success!");

        try {
            const response = await fetch(`${url}/reset-password/${token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ token, newPassword })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            if (data.success) {
                setSuccess('Password reset successfully. Please log in.');
            } else {
                setError(data.message || 'Error resetting password');
            }
        } catch (error) {
            console.error('Error during password reset:', error);
            setError('An error occurred while resetting the password.');
        }
        console.log(newPassword);
    };

    return (
        <div className="custom-container">
            <div className="col-md-6 custom-card-container">
                <div className="custom-card-body">
                    <h2 className="card-title text-center mb-4">Reset Password</h2>
                    {/* {statusMessage && <p className={`text-center ${statusMessage.includes("do not match") ? "text-danger" : "text-success"} custom-link`}>{statusMessage}</p>} */}
                    {success && <p className="text-success text-center custom-link">{success}</p>}
                    {error && <p className="text-danger text-center custom-link">{error}</p>}
                    <form onSubmit={handleResetPassword}>
                        <div className="custom-form-group">
                            <input
                                type="password"
                                className="form-control"
                                id="newPassword"
                                placeholder=" "
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                            />
                            <label htmlFor="newPassword">New Password</label>
                        </div>
                        <div className="custom-form-group">
                            <input
                                type="password"
                                className="form-control"
                                id="confirmPassword"
                                placeholder=" "
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                            <label htmlFor="confirmPassword">Confirm Password</label>
                        </div>
                        <div className="d-grid">
                            <button type="submit" className="btn custom-button btn-lg">Reset Password</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
    
}

export default ResetPasswordPage;
