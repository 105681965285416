import React from 'react';
import {
    Routes,
    Route
} from "react-router-dom";
import MainPage from './components/MainPage';
import LoginPage from './components/LoginPage';
import BookingPage from './components/BookingPage';
import HistoryPage from './components/HistoryPage';
import RegisterPage from './components/RegisterPage';
import ForgotpasswordPage from './components/ForgotPage'
import ResetPasswordPage from './components/ResetPage';

function App() {
    return (
        <div>
            <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/booking" element={<BookingPage />} />
                <Route path="/history" element={<HistoryPage />} />
                <Route path="/Register" element={<RegisterPage />} />
                <Route path="/forgot-password" element={<ForgotpasswordPage />} />
                <Route path="/reset-password/:token" element={<ResetPasswordPage />} />

            </Routes>
        </div>
    );
}

export default App;
